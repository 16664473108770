import React from "react"
import AndroidStoreImg from "../images/playstore.png"
import AppStoreImg from "../images/appstore.png"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"

class DownloadApp extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div className="mx-md-4 mx-0 mb-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-xs-12 text-center">
              <div className="download_app_heading font-samsung color-black">
                <p className="color-black">
                  Study Right! &nbsp;
                  <span className="heading-blue">Studi Now!</span>
                </p>
              </div>

              <span
                className="app-description font-lato"
                dangerouslySetInnerHTML={{
                  __html: this.props.data.nodes[0].body.processed,
                }}
              />

              <br />

              <div className="store-links text-center beside_image">
                <h5>
                  <span className="font-weight-bold">Download Now!</span>
                </h5>

                <a
                  href="https://play.google.com/store/apps/details?id=com.tce.studi"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={AndroidStoreImg}
                    alt="androidlogo"
                    title="Android App"
                    className="da_app_store_img play_store"
                  />
                </a>
                <a
                  href="https://apps.apple.com/in/app/tata-studi/id1532854969"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={AppStoreImg}
                    alt="appstorelogo"
                    title="IOS App"
                    className="da_app_store_img"
                  />
                </a>
              </div>
            </div>

            <div className="col-xs-12 col-lg-6">
              <div className="mx-lg-1 mx-0">
                <StaticImage
                  src="../images/downloadnow.jpg"
                  layout="fullWidth"
                  placeholder="tracedSVG"
                  alt="Tata Studi Features"
                  title="Tata Studi Features"
                  loading="lazy"
                />
              </div>
            </div>

            <div className="da_margin_twenty text-center justify-content-center below_image">
              <h5>
                <b>Download Now!</b>
              </h5>
              <a
                href="https://play.google.com/store/apps/details?id=com.tce.studi"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={AndroidStoreImg}
                  alt="androidlogo"
                  title="Android App"
                  className="da_app_store_img play_store"
                />
              </a>
              <a
                href="https://apps.apple.com/in/app/tata-studi/id1532854969"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={AppStoreImg}
                  alt="appstorelogo"
                  title="IOS App"
                  className="da_app_store_img"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default DownloadApp
