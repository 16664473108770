/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect , useState} from "react"
import { navigate, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ProductFeatures from "../components/productfeatures"
import DownloadApp from "../components/downloadapp"
import ContactForm from "../components/contactform"
import PhotoVideoCopy from "../components/photoVideoCopy"
import PropTypes from "prop-types"
import HomeBannerNew from "../components/newBannerHome"
import NewTestimonial from "../components/testimonialNew"
import HomepageAlert from "../components/HomepageAlert"

import useIsMobile from "../hooks/useIsMobile"

//import all CSS here to reduce compilation time
import "../css/globals.css"
import "../css/pageBanner.css"
import "../css/contactform.css"
import "../css/footer.css"
import "../css/downloadapp.css"
import "../css/faq.css"
import "../css/header.css"
import "../css/newhomebanner.css"
import "../css/layout.css"
import "../css/mobilefooter.css"
import "../css/newPayment.css"
import "../css/ourStory.css"
import "../css/photoVideo.css"
import "../css/termsConditions.css"
import "../css/productfeatures.css"
import "../css/rewards.css"
import "../css/supportContactUs.css"
import "../css/videolist.css"
import "../css/whatWeDo.css"

let propTypes = {
  data: PropTypes.object.isRequired,
}

export default function IndexPage(props) {
  const isMobile = useIsMobile('1024px');
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    let clickEvent = document
      .getElementById("navbar-brand")
      .addEventListener("click", function (e) {
        e.preventDefault()
        navigate("/")
      })
  
    return () => {
      removeEventListener("click", clickEvent);
    }
  }, [])

  // Home page alert popup start

  useEffect(() => {
    const alertTimer = setTimeout(() => {
      setShowAlert(true);
    }, 10000) 
  
    return () => {
      clearTimeout(alertTimer);
    }
  }, [])

  const scrollToForm = () => {
    document.getElementById("homepagealert")
      ? (document.getElementById("homepagealert").style.display = "none")
      : null;

    window.addEventListener("hashchange", function () {
      window.location.hash = ""
    })
  }

  const alertShow = () => {
    if (showAlert == true) {
      return (
        <div
          id="homepagealert"
          className="alert alert-primary alert-dismissible fade show text-center p-2 pt-3"
          role="alert"
          style={{ position: "fixed", right: "0", bottom: "0", zIndex: "50" }}
        >
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            style={{margin:"-12px"}}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <strong>Want to know more? </strong>

          <HomepageAlert closeAlert={scrollToForm} />
        </div>
      )
    } else {
      return <> </>
    }
  }
   // Home page alert popup end

  return (
    <Layout>
    <Helmet>
      <script type="application/ld+json">
        {`{
        "@context": "https://schema.org",
        "@type": "EducationalOrganization",
        "name": "Tata Studi",
        "alternateName": "Tata ClassEdge",
        "url": "https://www.tatastudi.com/",
        "logo": "https://www.tatastudi.com/static/studi_logo_1-b94e3003c3c1b2edf07181883ffb0d61.svg",
        "description": "Study with Tata ClassEdge is a personalised e-learning app for Class 1-8 students of NCERT, CBSE. It’s based on principle of Science of Learning. Studi prepares students to be exam-ready through planning, concept, videos and self-tests. It helps students plan, practice and perfect what they learn.",
        "sameAs": [
          "https://www.facebook.com/StudiWithTataClassEdge/",
          "https://twitter.com/StudiWithTCE/",
          "https://www.youtube.com/channel/UCZPDy3ChCpxudSJJAdB8wYw/",
          "https://www.linkedin.com/company/tataclassedge/"
        ],
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "3rd Floor, Plot No A 16-17, Part B Cross Lane, Near MIDC Police Station,Andheri(East), Mumbai",
          "postOfficeBoxNumber": "201301",
          "addressLocality": "Mumbai",
          "addressRegion": "Maharashtra",
          "postalCode": "400093",
          "addressCountry": "India"
        },
        "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+917506617777",
          "contactType": "Customer Service"
        }
      }`}
      </script>

      <script type="application/ld+json">
        {`
              {
                  "@context": "https://schema.org",
                  "@type": "WebSite",
                  "url": "https://www.tatastudi.com",
                  "potentialAction": {
                      "@type": "SearchAction",
                      "target": "https://www.tatastudi.com/?s={search_term_string}",
                      "query-input": "required name=search_term_string"
                  }
              }`}
      </script>
      <script type="application/ld+json">
        {`
      {
        "@context": "https://schema.org",
        "@type": "ImageObject",
        "contentUrl": "https://www.tatastudi.com/static/Tata_Studi_Banner_1-d6d90de03164db2856cabd12f298e8ca.jpg",
        "description": "Mother guide her son about online study with Tata Studi",
        "name": "Tata Studi"
      }`}
      </script>
      <script type="application/ld+json">
        {`
      {
        "@context": "https://schema.org",
        "@type": "ImageObject",
        "contentUrl": "https://www.tatastudi.com/static/Tata_Studi_Banner_2-036827430dd73630185477d3b55f4634.jpg",
        "description": "Girl learn from Tata Studi App",
        "name": "Learn with Tata Studi App"
      }`}
      </script>

      <script type="application/ld+json">
        {`
      {
        "@context": "https://schema.org",
        "@type": "ImageObject",
        "contentUrl": "https://www.tatastudi.com/static/e148b5e2e6e5c3761b2a0a68fdf1312c/b6808/Learn.png",
        "description": "Our methods employ effective and efficient study practices to create independent learners. How much time should your child spend on a subject? How should they prepare for an upcoming class test? Which topics does your child need to give more attention to? Through the Adaptive Studi Planner, we address these questions, and set your child on an efficient study path to achieve his or her learning goals.",
        "name": "Learn systematically using our Adaptive Studi Planner"
      }`}
      </script>
      <script type="application/ld+json">
        {`
      {
        "@context": "https://schema.org",
        "@type": "ImageObject",
        "contentUrl": "https://www.tatastudi.com/static/b018c522091205a0e70da07f1c2dbad9/46604/big-idea.png",
        "description": "Every chapter begins with its Big Idea that summarizes what it’s all about and how it matters.Why should I learn this?How does it matter?. These are two critical questions that most students have when asked to study something. Studi starts every chapter with a Big Idea, so children are able to grasp the big picture context of what they are about to learn.",
        "name": "Get the Big Idea"
      }`}
      </script>
      <script type="application/ld+json">
        {`
      {
        "@context": "https://schema.org",
        "@type": "ImageObject",
        "contentUrl": "https://www.tatastudi.com/static/eb8032d936a7f2c53c43b50d9524ded0/f24bc/Study.png",
        "description": "Studi is formulated based on the science of learning – the best practices in effective learning.  Studi draws its inspiration from the science of learning so that your child may develop better study habits. Each chapter is broken down into short, digestible chunks—videos, notes and summaries—to avoid learning overload. Practice sessions are spaced and repeated so that your child retains the concepts thoroughly. Self-tests are provided at periodic intervals to help gauge their confidence level at every step. In short, your child learns to be an effective learner for life.",
        "name": "Study the way the best do"
      }`}
      </script>

      <script type="application/ld+json">
        {`
      {
        "@context": "https://schema.org",
        "@type": "ImageObject",
        "contentUrl": "https://www.tatastudi.com/static/1bb04eb39b095826f00876c2d3834db3/06f82/Track.png",
        "description": "Studi enables non-intrusive supervision of your child’s progress. While your child learns to be an independent learner, you can join their learning journey by tracking their progress and provide the support and encouragement they fully deserve. Studi also enables you to involve yourself in their Studies by assigning tests, worksheets, quizzes and more.",
        "name": "Track your child’s progress"
      }`}
      </script>

      <script type="application/ld+json">
        {`
      {
        "@context": "https://schema.org",
        "@type": "ImageObject",
        "contentUrl": "https://www.tatastudi.com/static/d2f5afc70088b22e68347c542adda7ec/0fd62/howItWorksInfo.png",
        "description": "Whether it’s your child’s pace of learning, progress reports, or an upcoming test, Studi will keep you on track!Studi inspires your child to be an independent learner.",
        "name": "Study Right!  Studi Now!"
      }`}
      </script>
      <script type="application/ld+json">
        {`
      {
        "@context": "https://schema.org",
        "@type": "ImageObject",
        "contentUrl": "https://www.tatastudi.com/static/c62fe24763a17fc866f10db172d4f854/52258/download%2520now%2520banner.jpg",
        "description": "How Study Works- Adaptive Studi Planner, Progress Tracker, Big Idea Behind Every Chapter, Study the way the best do",
        "name": "Tata Studi Features!"
      }`}
      </script>

      <script type="application/ld+json">
        {`
      {
      "@context": "https://schema.org",
      "@type": "VideoObject",
      "name": "Tata Studi || Product Explainer Video",
      "description": "Tata Studi App",
      "thumbnailUrl": "https://www.youtube.com/embed/Tzp6w2o11YE%22",
      "uploadDate": "2021-03-04T08:00",
      "publisher": {
      "@type": "Organization",
      "name": "Tata Studi",
      "logo": {
      "@type": "ImageObject",
      "url": "https://www.tatastudi.com/static/studi_logo_1-b94e3003c3c1b2edf07181883ffb0d61.svg",
      "width": 645,
      "height": 363
      }
      },
      "contentUrl": "https://www.youtube.com/watch?v=Tzp6w2o11YE"
      }`}
      </script>
    </Helmet>

    <SEO
      title="Tata Studi: Class 1-12 Learning App for CBSE, NCERT"
      lang="en"
      description="Studi with Tata ClassEdge is a personalised e-Learning app for K-12 students of NCERT, CBSE, ICSE and State Boards. It’s based on principle of Science of Learning. Studi prepares students to be exam-ready through planning, concept videos and self-tests. It helps students plan, practice and perfect what they learn."
    />
    <div
      className="container-fluid nopadding"
      style={{ maxWidth: "100%", overflowX: "hidden" }}
    >
      <h1 className="displayNone">Experience Studi With TATA ClassEdge</h1>

      <HomeBannerNew />

      <ProductFeatures data={props.data.productfeatures} />

      <PhotoVideoCopy />

      <NewTestimonial slideCount={3} maxWidth="80%" />
     
      <DownloadApp data={props.data.downloadAppData} />

      <ContactForm />
      {/* calling the alert function */}
      {alertShow()}
    </div>
  </Layout>
  )
}

export const query = graphql`
  query {
    testimonials: allNodeTestimonials {
      nodes {
        body {
          processed
        }
        title
        field_testimonial_body

        field_testimonials_image {
          alt
        }
        relationships {
          field_testimonials_image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    productfeatures: allNodeProductFeatures {
      nodes {
        field_product_features_body_text {
          processed
        }
        field_product_features_heading {
          processed
        }
        field_product_features_image {
          alt
        }
        relationships {
          field_product_features_image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }

    downloadAppData: allNodeDownloadApp {
      nodes {
        title
        body {
          processed
        }
      }
    }
  }
`
