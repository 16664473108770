import React from "react"
import { navigate, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

class HomeBannerNew extends React.Component {
  componentDidMount() {
    //for info alert on top else remove this

    if (window.innerWidth < 440) {
      document.getElementById("wrap-banner").style.marginTop = "10%"
    } else {
      document.getElementById("wrap-banner").style.marginTop = "2.5%"
    }

    setTimeout(() => {
      try {
        if (!document.getElementById("next-btn")) {
          throw "Element not found!"
        }
        document.getElementById("next-btn").click()
      } catch (err) {
        console.log(err)
      }
    }, 3000)
  }

  guidedTour = () => {
    navigate("/explorestudi")
  }

  render() {
    return (
      <div>
        <div className="banner-wrapper" id="wrap-banner">
          <div
            id="carouselControl"
            className="carousel slide"
            data-ride="carousel"
            data-interval="5000"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="d-lg-none d-block mt-n2">
                  <StaticImage
                    src="../images/Mobile-Banner-1140x1210 (2).jpg"
                    layout="fullWidth"
                    placeholder="tracedSVG"
                    loading="eager"
                    alt="Tata Studi Banner"
                    title="Tata Studi Banner"
                    quality="50"
                  />
                </div>
                <div className="d-lg-block d-none mt-n4 desktop-banner">
                  <StaticImage
                    src="../images/Web-Banner1292x470 (4).jpg"
                    layout="fullWidth"
                    placeholder="tracedSVG"
                    loading="eager"
                    alt="Tata Studi Banner"
                    title="Tata Studi Banner"
                    aspectRatio={21 / 7}
                    quality="100"
                  />
                </div>
                <a href="https://learn.tatastudi.com/authentication/register/child-details">
                  <button
                    id="trial_button_white"
                    className="btn trial_button desktop_btn mb-n3"
                    // onClick={this.guidedTour}
                  >
                    <div className="trial_text">
                      Start Your Free Trial &nbsp;
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </div>
                  </button>
                </a>
              </div>

              <div className="carousel-item">
                <div className="d-lg-block d-none mt-n4 desktop-banner">
                  <StaticImage
                    src="../images/Web-Banner-Home-page-02.jpg"
                    layout="fullWidth"
                    placeholder="tracedSVG"
                    loading="lazy"
                    alt="Tata Studi Banner"
                    title="Tata Studi Banner"
                    aspectRatio={21 / 7}
                  />
                </div>
                <div className="d-lg-none d-block mt-n2">
                  <StaticImage
                    src="../images/Web-Banner-Mobile.jpg"
                    layout="fullWidth"
                    placeholder="tracedSVG"
                    loading="lazy"
                    alt="Tata Studi Banner"
                    title="Tata Studi Banner"
                    quality="50"
                  />
                </div>
                <Link to="https://liveadmin.tatastudi.com/registration">
                  <button
                    id="studilivebtn"
                    className="btn trial_button desktop_btn mt-n3"
                    // onClick={this.guidedTour}
                  >
                    <div className="trial_text">
                      Book a Demo Class &nbsp;
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </div>
                  </button>
                </Link>
              </div>















              <div className="carousel-item">
                <div className="d-lg-block d-none mt-n4 desktop-banner">
                  <StaticImage
                    src="../images/Web-Banner1292x431_04.jpg"
                    layout="fullWidth"
                    placeholder="tracedSVG"
                    loading="lazy"
                    alt="Tata Studi Banner"
                    title="Tata Studi Banner"
                    aspectRatio={21 / 7}
                  />
                </div>
                <div className="d-lg-none d-block mt-n2">
                  <StaticImage
                    src="../images/Mobile_01.jpg"
                    layout="fullWidth"
                    placeholder="tracedSVG"
                    loading="lazy"
                    alt="Tata Studi Banner"
                    title="Tata Studi Banner"
                    quality="50"
                  />
                </div>
                <Link to="https://leap.tatastudi.com/">
                  <button
                    id="studilivebtn"
                    className="btn trial_button desktop_btn mt-n3"
                  >
                    <div className="trial_text">
                      Take the Free Assessment &nbsp;
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </div>
                  </button>
                </Link>
              </div>



















            </div>
            <a
              className="carousel-control-prev"
              href="#carouselControl"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselControl"
              role="button"
              data-slide="next"
              id="next-btn"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>

          {/* <a href="https://learn.tatastudi.com/authentication/register/child-details">
            <button
              id="trial_button_white"
              className="btn trial_button desktop_btn"
              // onClick={this.guidedTour}
            >
              <div className="trial_text">
                Start Your Free Trial &nbsp;
                <i className="fa fa-chevron-right" aria-hidden="true"></i>
              </div>
            </button>
          </a> */}

          <div className="bottom-wrapper">
            <div className="bottom_text_card">
              <span className="bottom_text">#PadhneKaSahiTareeka</span>
            </div>

            <div className="yellow_border" />
          </div>
        </div>
      </div>
    )
  }
}

export default HomeBannerNew
